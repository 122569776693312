import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './auth/AuthContext'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ActivateAccount = React.lazy(() => import('./views/pages/login/ActivateAccount'))
const PasswordReset = React.lazy(() => import('./views/pages/login/PasswordReset'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const InstagramCallback = React.lazy(() => import('./views/pages/instagram/Instagram'))
const Privacy = React.lazy(() => import('./views/privacy/Privacy'))
const TermsAndConditions = React.lazy(() => import('./views/terms/TermsAndConditions'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route
                exact
                path="/activateaccount"
                name="Activate Page"
                element={<ActivateAccount />}
              />
              <Route exact path="/passwordreset" name="Login Page" element={<PasswordReset />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                exact
                path="/termsandconditions"
                name="Terms And Conditions Page"
                element={<TermsAndConditions />}
              />
              <Route exact path="/privacyPolicy" name="Privacy Policy Page" element={<Privacy />} />
              <Route
                exact
                path="/instagramCallback"
                name="Instagram Callback"
                element={<InstagramCallback />}
              />
              <Route path="/register/:referralCode" element={<Register />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    )
  }
}

export default App
